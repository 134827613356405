<template>
  <div>
    <!--导航栏--->
    <div>
      <van-nav-bar title="收货地址" left-arrow @click-left="$router.back(-1)" />
    </div>
    <!--导航栏--->

    <div>
      <van-swipe-cell 
          v-for="(item,index) in list" :key="index">
         <div class="list">
         <span class="name">{{item.name}}</span> {{item.tel}} <span class="text" v-if="item.isDefault">默认</span> 
          <van-icon class="icon" name="edit" />
          <div class="address">{{item.address}}</div> 
         </div>
          <template #right>
            <van-button square text="删除" type="danger" class="delete-button" />
          </template>
      </van-swipe-cell>
    </div>
    <!-- 底部 -->
     <div @click="$router.push('/shippingAddresstwo')">
      <van-goods-action>
       <van-goods-action-button
          type="primary"
          text="新建收货地址"
        />
      </van-goods-action>
    </div>
    <!-- 底部 -->
  </div>
</template>
<script>
import { Toast } from "vant";

export default {
  data() {
    return {
      chosenAddressId: "1",
      list: [
        {
          id: "1",
          name: "张三",
          tel: "13000000000",
          address: "浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室",
          isDefault: true
        },
        {
          id: "2",
          name: "李四",
          tel: "1310000000",
          address: "浙江省杭州市拱墅区莫干山路 50 号"
        }
      ],
      disabledList: [
        {
          id: "3",
          name: "王五",
          tel: "1320000000",
          address: "浙江省杭州市滨江区江南大道 15 号"
        }
      ]
    };
  },
  methods: {
    onAdd() {
      Toast("新增地址");
      this.$router.push({
        path: "/shippingAddressthree",
        query: {}
      });
    },
    onEdit(item, index) {
      Toast("编辑地址:" + index);
    }
  }
};
</script>
<style lang="less" scoped>
 /deep/.van-button{
  background-color: #F52556 ;
}

.delete-button {
    height: 100%;
    width: 57px;
    background-color: #F52556 ;
  }
.list{
  border-bottom: 1px #333 solid;
  margin: 0;
  padding: 0;
  position: relative;
  height: 74px;
  background-color: #FFFFFF;
  size: 14px;
}
.icon{
  position: absolute;
  right: 18px;bottom: 27px;
  font-size: 20px;
  color: #313144;
}
.name{
  display: inline-block;
  margin-top: 15px;
}
.text{
  display: inline-block;
  // margin-top: 15px;
  height: 17px;
  font-size: 12px;
  background-color: #FF0038 ;
  color: #fff;
}
.address{
   height: 17px;
   padding-top: 5px;
   font-size: 12px;
   color: #333;
   padding-bottom: 17px;
}
</style>